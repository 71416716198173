.el-tabs {
  --el-tabs-header-height: 40px;
  display: flex;
}

.el-tabs__header {
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
  padding: 0;
  display: flex;
  position: relative;
}

.el-tabs__header-vertical {
  flex-direction: column;
}

.el-tabs__active-bar {
  background-color: var(--el-color-primary);
  height: 2px;
  transition: width var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier), transform var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  z-index: 1;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.el-tabs__new-tab {
  border: 1px solid var(--el-border-color);
  color: var(--el-text-color-primary);
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 10px 0 10px 10px;
  font-size: 12px;
  line-height: 20px;
  transition: all .15s;
  display: flex;
}

.el-tabs__new-tab .is-icon-plus {
  height: inherit;
  width: inherit;
  transform: scale(.8);
}

.el-tabs__new-tab .is-icon-plus svg {
  vertical-align: middle;
}

.el-tabs__new-tab:hover {
  color: var(--el-color-primary);
}

.el-tabs__new-tab-vertical {
  margin-left: 0;
}

.el-tabs__nav-wrap {
  flex: auto;
  margin-bottom: -1px;
  position: relative;
  overflow: hidden;
}

.el-tabs__nav-wrap:after {
  background-color: var(--el-border-color-light);
  content: "";
  width: 100%;
  height: 2px;
  z-index: var(--el-index-normal);
  position: absolute;
  bottom: 0;
  left: 0;
}

.el-tabs__nav-wrap.is-scrollable {
  box-sizing: border-box;
  padding: 0 20px;
}

.el-tabs__nav-scroll {
  overflow: hidden;
}

.el-tabs__nav-next, .el-tabs__nav-prev {
  color: var(--el-text-color-secondary);
  cursor: pointer;
  text-align: center;
  width: 20px;
  font-size: 12px;
  line-height: 44px;
  position: absolute;
}

.el-tabs__nav-next {
  right: 0;
}

.el-tabs__nav-prev {
  left: 0;
}

.el-tabs__nav {
  float: left;
  transition: transform var(--el-transition-duration);
  white-space: nowrap;
  z-index: calc(var(--el-index-normal)  + 1);
  display: flex;
  position: relative;
}

.el-tabs__nav.is-stretch {
  min-width: 100%;
  display: flex;
}

.el-tabs__nav.is-stretch > * {
  text-align: center;
  flex: 1;
}

.el-tabs__item {
  box-sizing: border-box;
  color: var(--el-text-color-primary);
  font-size: var(--el-font-size-base);
  height: var(--el-tabs-header-height);
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-weight: 500;
  list-style: none;
  display: flex;
  position: relative;
}

.el-tabs__item:focus, .el-tabs__item:focus:active {
  outline: none;
}

.el-tabs__item:focus-visible {
  box-shadow: 0 0 2px 2px var(--el-color-primary) inset;
  border-radius: 3px;
}

.el-tabs__item .is-icon-close {
  text-align: center;
  transition: all var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  border-radius: 50%;
  margin-left: 5px;
}

.el-tabs__item .is-icon-close:before {
  display: inline-block;
  transform: scale(.9);
}

.el-tabs__item .is-icon-close:hover {
  background-color: var(--el-text-color-placeholder);
  color: #fff;
}

.el-tabs__item.is-active, .el-tabs__item:hover {
  color: var(--el-color-primary);
}

.el-tabs__item:hover {
  cursor: pointer;
}

.el-tabs__item.is-disabled {
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}

.el-tabs__content {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.el-tabs--bottom > .el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top > .el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 0;
}

.el-tabs--bottom > .el-tabs__header .el-tabs__item:last-child, .el-tabs--top > .el-tabs__header .el-tabs__item:last-child {
  padding-right: 0;
}

.el-tabs--bottom.el-tabs--border-card > .el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--bottom.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top.el-tabs--border-card > .el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 20px;
}

.el-tabs--bottom.el-tabs--border-card > .el-tabs__header .el-tabs__item:last-child, .el-tabs--bottom.el-tabs--card > .el-tabs__header .el-tabs__item:last-child, .el-tabs--top.el-tabs--border-card > .el-tabs__header .el-tabs__item:last-child, .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
  padding-right: 20px;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: 1px solid var(--el-border-color-light);
  height: var(--el-tabs-header-height);
}

.el-tabs--card > .el-tabs__header .el-tabs__nav-wrap:after {
  content: none;
}

.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid var(--el-border-color-light);
  box-sizing: border-box;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.el-tabs--card > .el-tabs__header .el-tabs__active-bar {
  display: none;
}

.el-tabs--card > .el-tabs__header .el-tabs__item .is-icon-close {
  transform-origin: 100%;
  width: 0;
  height: 14px;
  font-size: 12px;
  position: relative;
  right: -2px;
  overflow: hidden;
}

.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-bottom: 1px solid #0000;
  border-left: 1px solid var(--el-border-color-light);
  transition: color var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier), padding var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
}

.el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  border-left: none;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-closable:hover {
  padding-left: 13px;
  padding-right: 13px;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-closable:hover .is-icon-close {
  width: 14px;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: var(--el-bg-color);
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable {
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable .is-icon-close {
  width: 14px;
}

.el-tabs--border-card {
  background: var(--el-bg-color-overlay);
  border: 1px solid var(--el-border-color);
}

.el-tabs--border-card > .el-tabs__content {
  padding: 15px;
}

.el-tabs--border-card > .el-tabs__header {
  background-color: var(--el-fill-color-light);
  border-bottom: 1px solid var(--el-border-color-light);
  margin: 0;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__nav-wrap:after {
  content: none;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: var(--el-text-color-secondary);
  transition: all var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  border: 1px solid #0000;
  margin-top: -1px;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item + .el-tabs__item, .el-tabs--border-card > .el-tabs__header .el-tabs__item:first-child {
  margin-left: -1px;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background-color: var(--el-bg-color-overlay);
  border-left-color: var(--el-border-color);
  border-right-color: var(--el-border-color);
  color: var(--el-color-primary);
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: var(--el-color-primary);
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-disabled {
  color: var(--el-disabled-text-color);
}

.el-tabs--border-card > .el-tabs__header .is-scrollable .el-tabs__item:first-child {
  margin-left: 0;
}

.el-tabs--bottom {
  flex-direction: column;
}

.el-tabs--bottom .el-tabs__header.is-bottom {
  margin-top: 10px;
  margin-bottom: 0;
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__header.is-bottom {
  border-bottom: 0;
  border-top: 1px solid var(--el-border-color);
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__nav-wrap.is-bottom {
  margin-top: -1px;
  margin-bottom: 0;
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__item.is-bottom:not(.is-active) {
  border: 1px solid #0000;
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__item.is-bottom {
  margin: 0 -1px -1px;
}

.el-tabs--left, .el-tabs--right {
  overflow: hidden;
}

.el-tabs--left .el-tabs__header.is-left, .el-tabs--left .el-tabs__header.is-right, .el-tabs--left .el-tabs__nav-scroll, .el-tabs--left .el-tabs__nav-wrap.is-left, .el-tabs--left .el-tabs__nav-wrap.is-right, .el-tabs--right .el-tabs__header.is-left, .el-tabs--right .el-tabs__header.is-right, .el-tabs--right .el-tabs__nav-scroll, .el-tabs--right .el-tabs__nav-wrap.is-left, .el-tabs--right .el-tabs__nav-wrap.is-right {
  height: 100%;
}

.el-tabs--left .el-tabs__active-bar.is-left, .el-tabs--left .el-tabs__active-bar.is-right, .el-tabs--right .el-tabs__active-bar.is-left, .el-tabs--right .el-tabs__active-bar.is-right {
  width: 2px;
  height: auto;
  top: 0;
  bottom: auto;
}

.el-tabs--left .el-tabs__nav-wrap.is-left, .el-tabs--left .el-tabs__nav-wrap.is-right, .el-tabs--right .el-tabs__nav-wrap.is-left, .el-tabs--right .el-tabs__nav-wrap.is-right {
  margin-bottom: 0;
}

.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-next, .el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev, .el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-next, .el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-next, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev, .el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-next, .el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev {
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 30px;
  line-height: 30px;
}

.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-next i, .el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev i, .el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-next i, .el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev i, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-next i, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev i, .el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-next i, .el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev i {
  transform: rotate(90deg);
}

.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev, .el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev, .el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev {
  top: 0;
  left: auto;
}

.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-next, .el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-next, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-next, .el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-next {
  bottom: 0;
  right: auto;
}

.el-tabs--left .el-tabs__nav-wrap.is-left.is-scrollable, .el-tabs--left .el-tabs__nav-wrap.is-right.is-scrollable, .el-tabs--right .el-tabs__nav-wrap.is-left.is-scrollable, .el-tabs--right .el-tabs__nav-wrap.is-right.is-scrollable {
  padding: 30px 0;
}

.el-tabs--left .el-tabs__nav-wrap.is-left:after, .el-tabs--left .el-tabs__nav-wrap.is-right:after, .el-tabs--right .el-tabs__nav-wrap.is-left:after, .el-tabs--right .el-tabs__nav-wrap.is-right:after {
  width: 2px;
  height: 100%;
  top: 0;
  bottom: auto;
}

.el-tabs--left .el-tabs__nav.is-left, .el-tabs--left .el-tabs__nav.is-right, .el-tabs--right .el-tabs__nav.is-left, .el-tabs--right .el-tabs__nav.is-right {
  flex-direction: column;
}

.el-tabs--left .el-tabs__item.is-left, .el-tabs--right .el-tabs__item.is-left {
  justify-content: flex-end;
}

.el-tabs--left .el-tabs__item.is-right, .el-tabs--right .el-tabs__item.is-right {
  justify-content: flex-start;
}

.el-tabs--left {
  flex-direction: row-reverse;
}

.el-tabs--left .el-tabs__header.is-left {
  margin-bottom: 0;
  margin-right: 10px;
}

.el-tabs--left .el-tabs__nav-wrap.is-left {
  margin-right: -1px;
}

.el-tabs--left .el-tabs__active-bar.is-left, .el-tabs--left .el-tabs__nav-wrap.is-left:after {
  left: auto;
  right: 0;
}

.el-tabs--left .el-tabs__item.is-left {
  text-align: right;
}

.el-tabs--left.el-tabs--card .el-tabs__active-bar.is-left {
  display: none;
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  border-bottom: none;
  border-left: none;
  border-right: 1px solid var(--el-border-color-light);
  border-top: 1px solid var(--el-border-color-light);
  text-align: left;
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left:first-child {
  border-right: 1px solid var(--el-border-color-light);
  border-top: none;
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  border: 1px solid var(--el-border-color-light);
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #fff;
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active:first-child {
  border-top: none;
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active:last-child {
  border-bottom: none;
}

.el-tabs--left.el-tabs--card .el-tabs__nav {
  border-bottom: 1px solid var(--el-border-color-light);
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.el-tabs--left.el-tabs--card .el-tabs__new-tab {
  float: none;
}

.el-tabs--left.el-tabs--border-card .el-tabs__header.is-left {
  border-right: 1px solid var(--el-border-color);
}

.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
  border: 1px solid #0000;
  margin: -1px 0 -1px -1px;
}

.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left.is-active {
  border-color: #d1dbe5 #0000;
}

.el-tabs--right .el-tabs__header.is-right {
  margin-bottom: 0;
  margin-left: 10px;
}

.el-tabs--right .el-tabs__nav-wrap.is-right {
  margin-left: -1px;
}

.el-tabs--right .el-tabs__nav-wrap.is-right:after {
  left: 0;
  right: auto;
}

.el-tabs--right .el-tabs__active-bar.is-right {
  left: 0;
}

.el-tabs--right.el-tabs--card .el-tabs__active-bar.is-right {
  display: none;
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right {
  border-bottom: none;
  border-top: 1px solid var(--el-border-color-light);
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right:first-child {
  border-left: 1px solid var(--el-border-color-light);
  border-top: none;
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right.is-active {
  border: 1px solid var(--el-border-color-light);
  border-bottom: none;
  border-left: 1px solid #fff;
  border-right: none;
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right.is-active:first-child {
  border-top: none;
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right.is-active:last-child {
  border-bottom: none;
}

.el-tabs--right.el-tabs--card .el-tabs__nav {
  border-bottom: 1px solid var(--el-border-color-light);
  border-left: none;
  border-radius: 0 4px 4px 0;
}

.el-tabs--right.el-tabs--border-card .el-tabs__header.is-right {
  border-left: 1px solid var(--el-border-color);
}

.el-tabs--right.el-tabs--border-card .el-tabs__item.is-right {
  border: 1px solid #0000;
  margin: -1px -1px -1px 0;
}

.el-tabs--right.el-tabs--border-card .el-tabs__item.is-right.is-active {
  border-color: #d1dbe5 #0000;
}

.el-tabs--top {
  flex-direction: column-reverse;
}

.slideInLeft-transition, .slideInRight-transition {
  display: inline-block;
}

.slideInRight-enter {
  animation: slideInRight-enter var(--el-transition-duration);
}

.slideInRight-leave {
  animation: slideInRight-leave var(--el-transition-duration);
  position: absolute;
  left: 0;
  right: 0;
}

.slideInLeft-enter {
  animation: slideInLeft-enter var(--el-transition-duration);
}

.slideInLeft-leave {
  animation: slideInLeft-leave var(--el-transition-duration);
  position: absolute;
  left: 0;
  right: 0;
}

@keyframes slideInRight-enter {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0);
  }
}

@keyframes slideInRight-leave {
  0% {
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(100%);
  }
}

@keyframes slideInLeft-enter {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0);
  }
}

@keyframes slideInLeft-leave {
  0% {
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(-100%);
  }
}
/*# sourceMappingURL=index.6b4cacf7.css.map */
